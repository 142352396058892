import React from 'react';
import { navigateTo } from '../../helper/i18n/Link';
import { navigate } from 'gatsby';
import * as styles from './ListEntry.module.css';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Button from '../Common/Button';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { slug as slugFn } from '@mangoart/gatsby-ui/helper/Slugger';

function ListEntry({ node }) {
  const { name, slug, logopng, logojpg, logosvg, image } = node;

  const hasPng = !!logopng;
  const hasJpg = !!logojpg;
  const hasSvg = !!logosvg;

  const logoToUse = hasPng ? logopng : hasSvg ? logosvg : hasJpg ? logojpg : '';

  const logoUrl = logoToUse ? logoToUse.publicURL : null;
  const { t, i18n } = useTranslation('labels');
  const detailPageUrl = i18n.language === 'de' ? `/hersteller/${slugFn(name)}` : `/en/manufacturer/${slugFn(name)}`;

  return (
    <div
      className={styles.content}
      onClick={(e) => {
        // navigateTo(linkToHersteller, extraPath, i18n.language);
        navigate(detailPageUrl);
        e.preventDefault();
      }}
    >
      <div className={styles.top}>
        <GatsbyImage alt={name} image={getImage(image)} style={{ height: 170 }} />
      </div>
      <div className={styles.logo}>{logoUrl && <img src={logoUrl} alt={`Logo ${name}`} />}</div>
      <div className={styles.action}>
        <Button to={detailPageUrl} icon="arrow-right" style={{ padding: '.5em 2em .5em 1em' }}>
          {t('readmore')}
        </Button>
      </div>
    </div>
  );
}

export default ListEntry;
