import React from 'react';
import Link from 'Helpers/i18n/Link';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import * as styles from './Navigation.module.css';

function TeamNavigation() {
  const { t, i18n } = useTranslation('labels');

  const categories = [
    {
      id: 'landtechnik',
      name: t('manufacturer.agricultural'),
    },
    {
      id: 'motoristik',
      name: t('manufacturer.motoristik'),
    } /* ,
    {
      id: "ersatzteile_zubehoer",
      name: t('manufacturer.spareparts')
    } */,
  ];

  console.log(i18n.language);

  return (
    <div className={styles.content}>
      <ul>
        {categories.map((categorie) => {
          return (
            <li key={categorie.id}>
              <Link
                to={(i18n.language === 'en' ? '/en/manufacturer#' : '/hersteller#') + categorie.id}
                title={categorie.name}
              >
                {categorie.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default TeamNavigation;
