import * as React from 'react';
import { graphql } from 'gatsby';

import * as styles from './hersteller.module.css';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { PageTitle } from '../components/Common/PageTitle';
import Navigation from '../components/Hersteller/Navigation';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import ListEntry from '../components/Hersteller/ListEntry';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const sortHerstellerFunction = (a, b) => {
  if (a.starred && b.starred) {
    return a.order - b.order;
  } else if (a.starred) {
    return -1;
  } else if (b.starred) {
    return 1;
  }
  return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
};

const renderHersteller = (node) => <ListEntry node={node} key={node.id} />;

const HerstellerPage = ({ data, location }) => {
  const { siteMetadata } = data.site;
  const { title, icon, metadata, opengraph } = data.page;

  const sortedByCategory = {};
  data.manufacturers.edges.forEach(({ node }) => {
    sortedByCategory[node.category] = sortedByCategory[node.category] || [];
    sortedByCategory[node.category].push(node);
  });

  const { t } = useTranslation('menu');

  return (
    <DefaultLayout>
      <div className="wrap">
        <PageTitle title={title} icon={icon} />
        <Navigation />
        <PageTitle title={t('manufacturer.agricultural')} />
        <div className={styles.content}>
          <span id="landtechnik" className={styles.anchor} />
          {sortedByCategory['Landtechnik'].sort(sortHerstellerFunction).map(renderHersteller)}
          <div className="clear" />
        </div>

        <PageTitle title={t('manufacturer.motoristik')} />
        <div className={styles.content}>
          <span id="motoristik" className={styles.anchor} />
          {sortedByCategory['Motoristik'].sort(sortHerstellerFunction).map(renderHersteller)}
          <div className="clear" />
        </div>
      </div>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
    </DefaultLayout>
  );
};

export default HerstellerPage;

export const HerstellerPageQuery = graphql`
  query HerstellerPageQuery($language: String) {
    site: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: cockpitRegionHersteller(lang: { eq: $language }) {
      title
      icon
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    manufacturers: allCockpitHersteller(filter: { published: { eq: true }, lang: { eq: $language } }) {
      edges {
        node {
          id
          name
          starred
          slug
          description
          site
          category
          image {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
          logopng {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
          logosvg {
            publicURL
          }
          logojpg {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
